<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b4">{{ title }}</h1>
      <!-- 検索フォーム -->
      <form method="get" action="/annotations/search" id="SEARCH-FORM" name="Search">
        <input type="text" v-model="search_word" value="" name="keywords" class="keyword" /><input type="image" src="/img/btn_search.gif" value="" alt="検索" name="" class="submit" />
      </form>
      <!-- /検索フォーム -->
    </div>
    <!-- /H1 -->
    <!-- パンくず -->
    <div id="TOPIC-PATH"><a href="http://ebiki.jp/">トップページ</a> &gt; <a href="/">絵引データベース</a> &gt; <a :href="'/motifs/view/' + this.category.id">{{ category.name }}</a> &gt; <a :href="'/annotations/view/' + this.ebiki.id">{{ ebiki.name }}</a></div>
    <!-- /パンくず -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY">
      <!-- H2 -->
      <div class="h2-binder big-margin-bottom">
        <h2>{{ ebiki.name }}（{{ ebiki.name_kana }}）</h2>
        <p class="date">公開日:{{ ebiki.published | display_date | moment("YYYY/MM/DD") }}　最終更新日:{{ ebiki.modified | display_date | moment("YYYY/MM/DD") }}</p>
      </div>
      <!-- /H2 -->
      <!-- 2段組み -->
      <div class="pane-binder">
        <!-- 左側 -->
        <div class="left">
          <!-- 図像・物事画像 -->
          <h3>図像・物事画像</h3>
          <div class="main-description">
            <img :src="this.ebiki.image" width="100" class="thumbnail" alt="" />
          </div>
          <!-- /図像・物事画像 -->
          <!-- 解説 -->
          <template v-if="this.ebiki.description">
            <h3>解説</h3>
            <dl class="commentary">
              <dd class="last" v-html="this.ebiki.description"></dd>
            </dl>
          </template>
          <!-- /解説 -->
        </div>
        <!-- /左側 -->
        <!-- 右側 -->
        <div class="right">
          <!-- 作品画像 -->
          <h3>作品画像</h3>
          <!-- 画像 -->
          <div id="PAINTING"><img :src="work.image" width="300" alt="" @load="focusInEbiki" /></div>
          <!-- /画像 -->
          <dl class="annotation">
            <dt>シリーズ</dt>
            <dd><a :href="'/series/view/' + series.id">{{ series.name }}</a></dd>
            <dt>表題</dt>
            <dd><a :href="'/works/view/' + work.id">{{ work.name }}</a></dd>
          </dl>
          <!-- /作品画像 -->
        </div>
        <!-- /右側 -->
      </div>
      <!-- /2段組み -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { Backend } from '@/ajax/Backend'
const iiifAnnotation = Backend.get('annotation')
const iiifAnnotationList = Backend.get('list')
const iiifCanvas = Backend.get('canvas')

// @ is an alias to /src
import tagline from '@/components/tagline.vue'
import navidiv from '@/components/navi.vue'
import footerdiv from '@/components/footer.vue'

import ebikiMixin from '@/mixins/ebikiMixin.js'
import $ from 'jquery'

export default {
  name: 'annotation',
  mixins: [ ebikiMixin ],
  components: {
    tagline,
    navidiv,
    footerdiv
  },
  props: {
    annotation_id: { type: Number, required: true },
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest:  { type: Object, required: true },
    manifest_id:  { type: Number, required: true },
    title:  { type: String, required: true },
    meta_description:  { type: String, required: true },
    meta_keywords:  { type: String, required: true },
  },
  data: function () {
    return {
      annotation: {},
      canvas: {},
      annotationlist: {},
      canvas_id: 0,
      list_url: '',
    };
  },
  filters: {
    display_date: function(value) {
      return value ? value : '0000/00/00'
    }
  },
  computed: {
    series: function() {
      return {
        id: this.manifest_id,
        name: this.manifest.label
      }
    },
    category: function() {
      let data = {
        id: 0,
        name: ''
      }
      if (typeof this.annotationlist.within !== 'undefined') {
        data.id = this.annotationlist.within['@id'].match(/\d+$/)[0]
        data.name = this.annotationlist.within.label
      }
      return data
    },
    ebiki: function() {
      let data = {
        id: this.annotation_id,
        name: '',
        name_kana: '',
        description: '',
        image: '',
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        published: '',
        modified: ''
      }
      if (this.annotation) {
        data.name = this.annotation.label
        data.description = this.annotation.description
        if (typeof this.annotation.metadata !== 'undefined') {
          data.name_kana = this.findMetadata(this.annotation.metadata, "name_kana")
          data.published = this.findMetadata(this.annotation.metadata, "published")
          data.modified = this.findMetadata(this.annotation.metadata, "modified")
        }
        if (typeof this.annotation.resource !== 'undefined') {
          if (typeof this.annotation.resource['@id'] !== 'undefined') {
            data.image = this.annotation.resource['@id']
          }
        }
        if (typeof this.annotation.on !== 'undefined') {
          let xywh = this.annotation.on.match(/#xywh=(\d+),(\d+),(\d+),(\d+)/)
          if (xywh[0]) {
            data.x = xywh[1] * this.scaledown_rate
            data.y = xywh[2] * this.scaledown_rate
            data.w = xywh[3] * this.scaledown_rate
            data.h = xywh[4] * this.scaledown_rate
          }
        }
      }
      return data
    },
    work: function() {
      let data = {
        id: 0,
        name: '',
        image: '',
        image_width: 0
      }
      if (this.canvas) {
        data.id = this.canvas_id
        data.name = this.canvas.label
        if (typeof this.canvas.images !== 'undefined') {
          if (typeof this.canvas.images[0] !== 'undefined') {
            if (typeof this.canvas.images[0].resource['@id'] !== 'undefined') {
              data.image = this.canvas.images[0].resource['@id']
              data.image_width = this.canvas.images[0].resource.width
            }
          }
        }
      }
      return data
    },
    search_word: function() {
      return this.ebiki.name
    },
    scaledown_rate: function() {
      let rate = 0
      if (this.work.image_width) {
        rate = 300 / this.work.image_width
      }
      return rate
    }
  },
  watch: {
    $route: {
      handler: async function() {
        this.annotation = await this.getAnnotation(this.manifest_id, this.annotation_id)
      },
      immediate: true
    },
    annotation: {
      handler: function() {
        if (this.annotation) {
          this.canvas_id = this.annotation.on.match(/canvas\/(\d+)/)[1]
        }
      },
      deep: true
    },
    canvas_id: async function() {
      if (this.canvas_id) {
        this.canvas = await this.getCanvas(this.manifest_id, this.canvas_id)
      }
    },
    'annotation.within': async function() {
      if (this.annotation.within) {
        this.annotationlist = await this.getAnnotationList(this.annotation.within)
      }
    },
  },
  methods: {
    // アノテーションAPIの読み込み
    getAnnotation: function(manifest_id, annotation_id) {
      return iiifAnnotation.get(manifest_id, annotation_id)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    },
    // カンバスAPIの読み込み
    getCanvas: function(manifest_id, canvas_id) {
      return iiifCanvas.get(manifest_id, canvas_id)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    },
    // アノテーションリストAPIの読み込み
    getAnnotationList: function(url) {
      return iiifAnnotationList.getByURL(url)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    },
    // 作品画像の絵引部分をハイライトする
    focusInEbiki: function() {
      if (this.work.image_width) {
        // 引数 --> (x座標, y座標, 横幅, 縦幅)
        $('#PAINTING').ebiki(this.ebiki.x, this.ebiki.y, this.ebiki.w, this.ebiki.h);
      }
    },
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + ' ' + this.title + ' - ' + this.orgname,
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.sitename + ' ' + this.title + ' - ' + this.orgname },
        { property: 'og:description', content: this.description },
      ]
    },
  }
}
</script>
